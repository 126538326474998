<template>
  <v-main>
    <v-container>
      <v-col class="px-0 py-0" style="margin: 70px 0px">
        <v-row no-gutters align="start">
          <div
            style="
              width: 12px;
              height: 12px;
              background: #144fa9;
              border-radius: 50%;
              margin-top: 12px;
            "
          />
          <v-col>
            <v-row no-gutters align="center">
              <v-col style="margin-left: 8px">
                <h2
                  style="
                    color: var(--Black, #1b1b1b);
                    font-family: 'MacPaw Fixel';
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                  "
                >
                  {{ "blog_section" | localize }}
                </h2>
                <p
                  style="
                    color: var(--Black, #1b1b1b);
                    font-family: 'MacPaw Fixel';
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin: 0px;
                  "
                >
                  {{ "blog_section_subtitle" | localize }}
                </p>
              </v-col>
              <v-row
                no-gutters
                justify="end"
                align="center"
                v-if="!$vuetify.breakpoint.smAndDown"
              >
                <button class="showAllBtn" @click="$router.push('/blog')">
                  {{ "show_all_btn" | localize }}
                </button>
              </v-row>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          align="center"
          style="margin-top: 40px"
          v-if="!$vuetify.breakpoint.smAndDown"
        >
          <v-col
            cols="12"
            xl="6"
            lg="6"
            md="6"
            sm="12"
            class="py-0"
            :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-right: 10px'"
            v-if="contentList.length > 0"
          >
            <blog-card
              :blog="contentList?.[0]"
              :isPopular="contentList?.[0]?.is_popular == 'Yes'"
              style="width: 100%"
            />
          </v-col>
          <v-col
            cols="12"
            xl="6"
            lg="6"
            md="6"
            sm="12"
            class="py-0"
            :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-left: 10px'"
          >
            <blog-card
              v-for="content in contentList.slice(1, 3)"
              :key="content.id"
              :blog="content"
              :isMain="true"
              :isPopular="content?.is_popular == 'Yes'"
            />
          </v-col>
        </v-row>
        <swiper
          v-else
          ref="mySwiper"
          :options="swiperMobileOptions"
          style="margin-top: 40px"
        >
          <swiper-slide
            v-for="content in contentList"
            :key="content.id"
            :cssMode="true"
            :navigation="true"
            :pagination="true"
            :mousewheel="true"
            :keyboard="true"
            :modules="swiperMobileOptions.modules"
          >
            <blog-card
              :blog="content"
              :isPopular="content?.is_popular == 'Yes'"
            /> </swiper-slide
        ></swiper>
        <submit-outline-button
          @close="$router.push('/blog')"
          v-if="$vuetify.breakpoint.smAndDown"
          >{{ "show_all_btn" | localize }}</submit-outline-button
        >
      </v-col>
    </v-container>
  </v-main>
</template>

<script>
import { Pagination, Navigation } from "vue-awesome-swiper";
import blogCard from "../../Blog/blogCard.vue";
import swiperOptionMixin from "../../../../mixins/swiperOptionMixin";
import SubmitOutlineButton from "../../../UI/Buttons/submitOutlineButton.vue";
import contentService from "../../../../requests/Content/contentService";
export default {
  mixins: [swiperOptionMixin],
  components: { blogCard, SubmitOutlineButton },
  data: () => ({
    swiperMobileOptions: {
      slidesPerView: 1.2,
      spaceBetween: 20,
      pagination: {
        el: ".swiper-pagination",
      },
      // navigation: {
      //   nextEl: ".swiper-button-next-choose-us",
      //   prevEl: ".swiper-button-prev-choose-us ",
      // },
      modules: [Navigation, Pagination],
    },
    contentList: [],
  }),
  mounted() {
    this.getImportantContent();
  },
  methods: {
    async getImportantContent() {
      await contentService.getImportantBlog().then((res) => {
        if (res.status == "Success") {
          this.contentList = res.data;
        }
      });
    },
  },
};
</script>

<style scoped>
.showAllBtn {
  color: var(--Black, #1b1b1b);
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 10px;
  border: 1px solid rgba(20, 79, 169, 0.5);
  width: 180px;
  height: 40px;
  display: grid;
  place-items: center;
}
</style>